(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/link-item-route/link-item-route.js') >= 0) return;  svs.modules.push('/components/lb-utils/link-item-route/link-item-route.js');
"use strict";

const _excluded = ["icon", "to", "iconPosition", "iconSize", "relativeTo", "selectedOnPaths"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const {
  useRouteMatch,
  Route
} = ReactRouterDOM;
const {
  LinkItem,
  ItemsMenuIcon
} = svs.ui.ReactItemsMenu;
const LinkItemRoute = _ref => {
  let {
      icon,
      to = {},
      iconPosition = 'top',
      iconSize = '200',
      relativeTo,
      selectedOnPaths = []
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const match = useRouteMatch();
  return React.createElement(Route, {
    exact: true,
    path: selectedOnPaths
  }, _ref2 => {
    let {
      match: matchPath
    } = _ref2;
    const location = _objectSpread({}, to);
    const relativeUrl = relativeTo ? relativeTo.url : match.url;
    if (relativeUrl.endsWith('/') && location.pathname.startsWith('/')) {
      location.pathname = relativeUrl + location.pathname.slice(1);
    } else {
      location.pathname = relativeUrl + location.pathname;
    }
    return React.createElement(LinkItem, _extends({
      selected: Boolean(matchPath),
      to: location
    }, props), Boolean(icon) && React.createElement(ItemsMenuIcon, {
      icon: icon,
      position: iconPosition,
      size: iconSize
    }));
  });
};
setGlobal('svs.components.lbUtils.linkItemRoute.LinkItemRoute', LinkItemRoute);

 })(window);